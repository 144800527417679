import React, { useEffect, useState } from 'react';
import { useSocket } from 'utils/hooks/useSocket';
import { formatCurrency } from 'utils';
import Cookies from 'js-cookie';
import { getTournamentRanking } from 'api/tournamentService';

import {
  TournamentRank,
  TournamentList,
  NickName,
  Percent,
  Amount,
  TournamentBelt,
  WrappList,
} from './styled';

type Balance = {
  BTC: number;
  USDT: number;
};

type TournamentRankingData = [string, string][]; // Updated to reflect the new data structure

type TournamentDetails = {
  data?: {
    id?: number;
    attributes?: {
      name?: string;
      end_at?: string;
      start_at?: string;
      amount_start?: number;
      market_type?: any;
      trade_type?: string;
    };
  };
};

const TournamentRankingList = () => {
  const { tournamentRanking, tournamentDetails } = useSocket() as { tournamentRanking: TournamentRankingData, tournamentDetails: TournamentDetails};
  const username = localStorage.getItem('username') ?? Cookies.get('username');
  const tournamentType = tournamentDetails?.data?.attributes?.trade_type;
  // const { totalMoneyString } = useWalletData();
  // const totalUSDT = tournamentType === "Margin" ? tournamentRanking[username] : totalMoneyString;
  const [initialMoney, setInitialMoney] = useState(0);
  const [localTournamentRanking, setLocalTournamentRanking] = useState<TournamentRankingData | null>(null);

  useEffect(() => {
    const checkTournamentRanking = async () => {
      const tournamentId = tournamentDetails?.data?.id;
      const initialMoney = tournamentDetails?.data?.attributes?.amount_start || 1;
      setInitialMoney(initialMoney);

      if (tournamentRanking && tournamentRanking.length === 0 && tournamentId) {
        try {
          const apiData = await getTournamentRanking(tournamentId);
          setLocalTournamentRanking(apiData);
        } catch (error) {
          console.error('Error fetching tournament ranking:', error);
        }
      } else {
        setLocalTournamentRanking(tournamentRanking);
      }
    };

    checkTournamentRanking();

  }, [tournamentRanking, tournamentDetails?.data?.id, tournamentDetails?.data?.attributes?.amount_start]);

  return (
    <TournamentRank className={tournamentType}>
      <>
        {(Array.isArray(localTournamentRanking) ? localTournamentRanking : []).map(([player, balanceString]) => {
          const totalUSDT = parseFloat(balanceString);
          const percentageChange = ((totalUSDT - initialMoney) / initialMoney) * 100;
          const isInvalidData = isNaN(totalUSDT) || isNaN(percentageChange);
          return (
            <TournamentList key={player}>
              <WrappList className={player === username ? 'is_me' : ''}>
                <TournamentBelt className={player === username ? 'is_me' : ''}>
                  <NickName>{player}</NickName>
                  {!isInvalidData && (
                    <Percent>
                      {percentageChange > 0 ? `+${percentageChange.toFixed(2)}` : percentageChange.toFixed(2)}%
                    </Percent>
                  )}
                </TournamentBelt>
                {!isInvalidData && (
                  <Amount className={`AmountDetails ${totalUSDT > initialMoney ? 'up_state' : totalUSDT < initialMoney ? 'down_state' : ''}`}>
                    {formatCurrency(totalUSDT)} USDT
                  </Amount>
                )}
              </WrappList>
            </TournamentList>
          );
        })}
      </>
    </TournamentRank>
  );
};

export default TournamentRankingList;
