import React from 'react';

import {
  Wrap,
  Text,
} from './styled';


const WinInformationCoinlistings = () => {
  return (
    <Wrap>
      <Text>
        Please, reach out to Coinlistings on Telegram: @Cornybtc‍ and provide your wallet address (Tron network, USDT) to collect the reward 
      </Text>
    </Wrap>
  );
};

export default WinInformationCoinlistings;
